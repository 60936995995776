import {
  InstrumentAction,
  InstrumentEvent,
  InstrumentStatus,
  InstrumentTagType,
  InstrumentUploaderType,
} from '@finverity/graphql';
import { FinButtonAppearance } from '@finverity/ui-kit';

import { InstrumentApprovalConfigs, InstrumentApprovalMakerSubscriptionEvents } from '../../../types';
import { InstrumentApprovalMakerDataGridColId } from '../../instrument-approval-maker-container/constants';

export enum SellerFinanceRequestMakerFilterIdentifier {
  PendingSellerFinanceMaker = 'PENDING_SELLER_FINANCE_MAKER',
  NotFundable = 'NOT_FUNDABLE',
}

export const SELLER_INSTRUMENT_FINANCE_REQUEST_MAKER_CONFIGS: InstrumentApprovalConfigs = {
  companyType: InstrumentUploaderType.Seller,
  pageTitle: 'Seller Finance Request (Maker)',
  actionsDefs: [
    {
      buttonText: 'Review & Approve',
      buttonSvgIconName: 'fvIconInstrumentApprovalReview',
      buttonAppearance: FinButtonAppearance.Primary,
      actionType: InstrumentAction.SellerFinanceMakerApprove,
      disabled: true,
      disabledTooltip: null,
    },
    {
      buttonText: 'Reject',
      buttonSvgIconName: 'fvIconInstrumentApprovalReject',
      buttonAppearance: FinButtonAppearance.OutlineAccent,
      actionType: InstrumentAction.SellerFinanceMakerReject,
      disabled: true,
      disabledTooltip: null,
    },
  ],
  filtersDefs: [
    {
      identifier: SellerFinanceRequestMakerFilterIdentifier.PendingSellerFinanceMaker,
      label: 'Pending Seller Finance Request (Maker)',
      params: {
        statuses: [InstrumentStatus.SellerFinancePendingMakerConfirmation],
        tags: {
          notIncludes: [
            InstrumentTagType.Deleted,
            InstrumentTagType.NotFundable,
            InstrumentTagType.NoEligibleFundingWindow,
          ],
        },
      },
      count: 0,
      hiddenColumns: [],
    },
    {
      identifier: SellerFinanceRequestMakerFilterIdentifier.NotFundable,
      label: 'Not Fundable',
      params: {
        statuses: [InstrumentStatus.SellerFinancePendingMakerConfirmation],
        tags: {
          includes: [InstrumentTagType.NotFundable, InstrumentTagType.NoEligibleFundingWindow],
        },
      },
      count: 0,
      hiddenColumns: [
        InstrumentApprovalMakerDataGridColId.DisbursementDate,
        InstrumentApprovalMakerDataGridColId.Tenor,
        InstrumentApprovalMakerDataGridColId.RepaymentDate,
        InstrumentApprovalMakerDataGridColId.AdvanceRate,
        InstrumentApprovalMakerDataGridColId.PrincipalAmount,
        InstrumentApprovalMakerDataGridColId.DisbursementAmount,
        InstrumentApprovalMakerDataGridColId.RepaymentAmount,
        InstrumentApprovalMakerDataGridColId.NetUnfinancedBalance,
        InstrumentApprovalMakerDataGridColId.SellerFeesInAdvance,
        InstrumentApprovalMakerDataGridColId.PayerFeesInAdvance,
        InstrumentApprovalMakerDataGridColId.SellerFeesInArrears,
        InstrumentApprovalMakerDataGridColId.PayerFeesInArrears,
        InstrumentApprovalMakerDataGridColId.TotalSellerFees,
        InstrumentApprovalMakerDataGridColId.TotalPayerFees,
        InstrumentApprovalMakerDataGridColId.TotalFees,
      ],
    },
  ],
  subscriptionEvents: <InstrumentApprovalMakerSubscriptionEvents>{
    approvedSuccess: [InstrumentEvent.SellerFinanceCheckerPending],
    approvedFailure: [InstrumentEvent.SellerFinanceMakerPending],
    rejected: [InstrumentEvent.SellerFinanceMakerRejected],
    updated: [InstrumentEvent.InstrumentEdited],
    removed: [InstrumentEvent.InstrumentDeleted],
  },
};
